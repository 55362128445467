import { defaultFlowRate } from '@/constants'
import { FlowRate, Torex } from '@/types'
import { getFlowRatePerSecond } from '@/utils'
import { useCallback } from 'react'
import { formatEther, parseEther } from 'viem'
import { useStaking } from './useStaking'

export const usePoolEstimationFns = () => {
  const { torexesWithStaking } = useStaking()

  const estimateUserRewardFlowrate = useCallback(
    (torex?: Torex, flowRate?: FlowRate) => {
      if (!torex || !flowRate || !torexesWithStaking[torex.address]) {
        return defaultFlowRate
      }

      const perSecondFlowRate = getFlowRatePerSecond(flowRate)
      const torexWithStaking = torexesWithStaking[torex.address]
      const totalVolumeEth = torexWithStaking.totalInFlowrate + perSecondFlowRate

      const sharePercentage = parseEther(flowRate.amountEth) / totalVolumeEth
      const totalRewardFlowrateEth = torexWithStaking.totalRewardFlowrate

      return {
        amountEth: formatEther(totalRewardFlowrateEth * sharePercentage),
        period: flowRate.period
      }
    },
    [torexesWithStaking]
  )

  return {
    estimateUserRewardFlowrate
  }
}
