import { ChainId } from '@/config/networks'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { cfAv1ForwarderABI, cfAv1ForwarderAddress, gdAv1ForwarderABI, gdAv1ForwarderAddress } from 'evm-contracts'
import { Address, zeroAddress } from 'viem'
import { useReadContract } from 'wagmi'

export default function useUserNetflow(userChainId: ChainId, inboundToken?: SuperTokenInfo, user?: Address) {
  const { data: cfaNetFlow } = useReadContract({
    address: cfAv1ForwarderAddress[userChainId],
    abi: cfAv1ForwarderABI,
    functionName: 'getAccountFlowrate',
    args: [inboundToken?.address as Address, user ?? zeroAddress],
    query: {
      enabled: inboundToken?.address !== undefined && user !== undefined
    }
  })

  const { data: gdaNetFlow } = useReadContract({
    address: gdAv1ForwarderAddress[userChainId],
    abi: gdAv1ForwarderABI,
    functionName: 'getNetFlow',
    args: [inboundToken?.address as Address, user ?? zeroAddress],
    query: {
      enabled: inboundToken?.address !== undefined && user !== undefined
    }
  })

  return (cfaNetFlow ?? 0n) + (gdaNetFlow ?? 0n)
}
