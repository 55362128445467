import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { useSearchParams } from 'next/navigation'
import { useMemo } from 'react'
import { isAddress } from 'viem'
import { useTorexTokens } from './useTorexTokens'

const findToken = (tokens: SuperTokenInfo[], query?: string | null) => {
  if (!query) return undefined

  return isAddress(query)
    ? tokens.find(token => token.address.toLowerCase() === query.toLowerCase())
    : tokens.find(token => token.symbol.toLowerCase() === query.toLowerCase())
}

export const useTorexQueryParams = () => {
  const searchParams = useSearchParams()
  const { inboundTokens, outboundTokens } = useTorexTokens()

  const inToken = useMemo(() => findToken(inboundTokens, searchParams.get('in')), [searchParams, inboundTokens])
  const outToken = useMemo(() => findToken(outboundTokens, searchParams.get('out')), [searchParams, outboundTokens])
  const volume = searchParams.get('volume')

  return {
    inToken,
    outToken,
    volume
  }
}

export function createTorexUrl(inToken?: SuperTokenInfo, outToken?: SuperTokenInfo, volume?: string) {
  const params = new URLSearchParams()

  if (inToken) {
    params.set('in', inToken.symbol)
  }

  if (outToken) {
    params.set('out', outToken.symbol)
  }

  if (volume) {
    params.set('volume', volume)
  }

  const { protocol, hostname, port } = location
  const urlBase = `${protocol}//${hostname}${port ? `:${port}` : ''}`

  return `${urlBase}/dca?${params.toString()}`
}
