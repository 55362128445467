import { ChainId } from '@/config/networks'
import { BLOCK_CONFIRMATIONS, BatchOperation, EMPTY_BYTES } from '@/constants'
import { safeWaitForTransactionReceipt, useIsContractWallet } from '@/hooks/useSafeWaitTransaction'
import { FlowRate, Torex } from '@/types'
import { adjustDecimals, getFlowRatePerSecond } from '@/utils'
import { analytics } from '@/utils/analytics'
import {
  gdAv1ForwarderABI,
  gdAv1ForwarderAddress,
  iConstantFlowAgreementV1ABI,
  iConstantFlowAgreementV1Address,
  iGeneralDistributionAgreementV1ABI,
  iGeneralDistributionAgreementV1Address,
  iSuperfluidABI,
  iSuperfluidAddress,
  macroForwarderABI,
  macroForwarderAddress,
  sb712MacroABI,
  sb712MacroAddress,
  torexABI
} from 'evm-contracts'
import { useCallback, useEffect, useState } from 'react'
import usePrevious from 'react-use/lib/usePrevious'
import {
  Address,
  UserRejectedRequestError,
  encodeAbiParameters,
  encodeFunctionData,
  formatEther,
  isAddress,
  parseAbiParameters,
  toHex,
  zeroAddress
} from 'viem'
import { readContract } from 'viem/actions'
import { useAccount, useChainId, usePublicClient, useWriteContract } from 'wagmi'
import { useAllTorexes } from './useAllTorexes'
import { useBenchmarkQuote } from './useBenchmarkQuote'
import useSignFlowUpdate from './useSignFlowUpdate'
import { useStreamActions } from './useStreamActions'
import { useTokenRelations } from './useTokenRelations'

interface DCAParams {
  flowRate?: FlowRate
  referrer?: Address
  amountToWrap?: bigint
}

interface UseTorexParams {
  user?: Address
  inboundToken?: Address
  outboundToken?: Address
}

const distributorFeeAddress = process.env.NEXT_PUBLIC_DISTRIBUTOR_FEE_ADDRESS

export const useTorex = ({ user, inboundToken, outboundToken }: UseTorexParams) => {
  const client = usePublicClient()
  const userChainId = useChainId() as ChainId
  const { address } = useAccount()
  const {
    data: { isContract: isAddressContract }
  } = useIsContractWallet(address)

  const { getTorexByTokens } = useAllTorexes()
  const { getUnderlyingTokenOf, isNativeAssetSuperToken } = useTokenRelations()

  const [torex, setTorex] = useState<Torex>()
  const [hasInverse, setHasInverse] = useState(false)
  const { flowExists } = useStreamActions()

  const [benchmarkQuote, durationSinceLastLME] = useBenchmarkQuote({
    torex: torex?.address
  })
  const [existingFlowRate, setExistingFlowrate] = useState(0n)
  const [isLoading, setIsLoading] = useState(false)

  const prevInboundToken = usePrevious(inboundToken)
  const prevOutboundToken = usePrevious(outboundToken)

  const { writeContractAsync } = useWriteContract()

  useEffect(() => {
    const effect = async () => {
      if (!inboundToken || !outboundToken || (inboundToken === prevInboundToken && outboundToken === prevOutboundToken))
        return

      const torex = getTorexByTokens(inboundToken, outboundToken)
      const inverseTorex = getTorexByTokens(outboundToken, inboundToken)

      if (!torex) return

      setTorex(torex)
      setHasInverse(Boolean(inverseTorex))
    }

    effect()
  }, [inboundToken, outboundToken, prevInboundToken, prevOutboundToken, getTorexByTokens])

  useEffect(() => {
    const effect = async () => {
      if (!torex || !inboundToken) return

      const flowrate = await flowExists(inboundToken, torex.address)
      setExistingFlowrate(flowrate)
    }

    effect()
  }, [flowExists, inboundToken, torex])

  const signFlowUpdate = useSignFlowUpdate()

  const dca = useCallback(
    async ({ flowRate, referrer = zeroAddress, amountToWrap = 0n }: DCAParams) => {
      let success = false

      if (!torex || !user || !client || !flowRate || !address) {
        return { success }
      }

      setIsLoading(true)

      const fps = getFlowRatePerSecond(flowRate)

      let adjustedWrapAmount: bigint
      // If the inbound token is the native asset, we don't need to adjust the decimals
      if (isNativeAssetSuperToken(torex.inboundToken.address)) {
        // TODO: Currently native assets are not supported by the macro. Modify this when they are.
        adjustedWrapAmount = 0n
      } else {
        const underlyingToken = getUnderlyingTokenOf(torex.inboundToken.address)

        adjustedWrapAmount = adjustDecimals(amountToWrap, underlyingToken?.decimals ?? 18, torex.inboundToken.decimals)
      }

      async function dcaWith712Support() {
        console.log('Detected user wallet. Using eip712 method to start dca')

        if (!torex || !client) {
          throw new Error('Missing torex or client')
        }

        const supportedLanguages = ['en', 'hu']

        let lang = navigator.languages.find(lang => supportedLanguages.includes(lang.split('-')[0])) ?? 'en'

        lang = lang.split('-')[0]

        const LANG_32 = toHex(lang, { size: 32 })

        const [actionCode, message, actionParams] = await readContract(client, {
          address: sb712MacroAddress[userChainId],
          abi: sb712MacroABI,
          functionName: 'encode712SetDCAPosition',
          args: [
            LANG_32,
            {
              distributor: distributorFeeAddress,
              referrer,
              torex: torex.address,
              flowRate: fps,
              upgradeAmount: adjustedWrapAmount
            }
          ]
        })

        const { r, s, v } = await signFlowUpdate({
          message,
          torex: torex.address,
          flowRate: fps,
          distributor: distributorFeeAddress,
          referrer,
          upgradeAmount: adjustedWrapAmount
        })

        const signatureVRS = encodeAbiParameters(parseAbiParameters(['uint8, bytes32, bytes32']), [Number(v), r, s])

        const params = encodeAbiParameters(parseAbiParameters(['uint8', 'bytes32', 'bytes, bytes']), [
          actionCode,
          LANG_32,
          actionParams,
          signatureVRS
        ])

        const hash = await writeContractAsync({
          address: macroForwarderAddress[userChainId],
          abi: macroForwarderABI,
          functionName: 'runMacro',
          args: [sb712MacroAddress[userChainId], params]
        })

        return hash
      }

      async function dcaWithBatchOperations() {
        if (!torex || !client || !user || !flowRate) {
          throw new Error('Missing torex or client')
        }

        console.log('Detected smartcontract. Fallingback to non-eip712 method to handle dca')

        const { distribuitionPool, inboundToken } = torex

        const [isUserConnectedToOutTokenPool, estimateApporvalRequired] = await Promise.all([
          readContract(client, {
            address: gdAv1ForwarderAddress[userChainId],
            abi: gdAv1ForwarderABI,
            functionName: 'isMemberConnected',
            args: [distribuitionPool, user]
          }),
          readContract(client, {
            address: torex.address,
            abi: torexABI,
            functionName: 'estimateApprovalRequired',
            args: [fps]
          })
        ])

        const batchTxs: {
          operationType: BatchOperation
          target: Address
          data: `0x${string}`
        }[] = []

        // op: upgrade
        if (adjustedWrapAmount > 0n) {
          const upgradeCallData = encodeAbiParameters(parseAbiParameters('uint256 amount'), [adjustedWrapAmount])

          batchTxs.push({
            operationType: BatchOperation.OPERATION_TYPE_SUPERTOKEN_UPGRADE,
            target: torex.inboundToken.address,
            data: upgradeCallData
          })
        }

        // op: approve Torex to use SuperToken
        if (estimateApporvalRequired > 0n) {
          const approveCallData = encodeAbiParameters(parseAbiParameters('address spender, uint256 amount'), [
            torex.address,
            estimateApporvalRequired
          ])

          batchTxs.push({
            operationType: BatchOperation.OPERATION_TYPE_ERC20_APPROVE,
            target: inboundToken.address,
            data: approveCallData
          })
        }

        // op: create or update flow
        if (!existingFlowRate) {
          const createFlowCallData = encodeFunctionData({
            abi: iConstantFlowAgreementV1ABI,
            functionName: 'createFlow',
            args: [inboundToken.address, torex.address, fps, EMPTY_BYTES]
          })

          batchTxs.push({
            operationType: BatchOperation.OPERATION_TYPE_SUPERFLUID_CALL_AGREEMENT,
            target: iConstantFlowAgreementV1Address[userChainId],
            data: encodeAbiParameters(parseAbiParameters('bytes, bytes'), [
              createFlowCallData,
              encodeAbiParameters(parseAbiParameters('address, address'), [
                distributorFeeAddress,
                isAddress(referrer) ? referrer : zeroAddress
              ])
            ])
          })
        } else {
          const updateFlowCalldata = encodeFunctionData({
            abi: iConstantFlowAgreementV1ABI,
            functionName: 'updateFlow',
            args: [inboundToken.address, torex.address, fps, EMPTY_BYTES]
          })

          batchTxs.push({
            operationType: BatchOperation.OPERATION_TYPE_SUPERFLUID_CALL_AGREEMENT,
            target: iConstantFlowAgreementV1Address[userChainId],
            data: encodeAbiParameters(parseAbiParameters('bytes, bytes'), [
              updateFlowCalldata,
              encodeAbiParameters(parseAbiParameters('address, address'), [
                distributorFeeAddress,
                isAddress(referrer) ? referrer : zeroAddress
              ])
            ])
          })
        }

        // op: connect outTokenDistributionPool
        if (!isUserConnectedToOutTokenPool) {
          const connectToDistributionPoolCallData = encodeFunctionData({
            abi: iGeneralDistributionAgreementV1ABI,
            functionName: 'connectPool',
            args: [distribuitionPool, EMPTY_BYTES]
          })

          batchTxs.push({
            operationType: BatchOperation.OPERATION_TYPE_SUPERFLUID_CALL_AGREEMENT,
            target: iGeneralDistributionAgreementV1Address[userChainId],
            data: encodeAbiParameters(parseAbiParameters('bytes, bytes'), [
              connectToDistributionPoolCallData,
              EMPTY_BYTES
            ])
          })
        }

        const hash = await writeContractAsync({
          address: iSuperfluidAddress[userChainId],
          abi: iSuperfluidABI,
          functionName: 'batchCall',
          args: [batchTxs]
        })

        return hash
      }

      try {
        let hash: Address

        if (isAddressContract) {
          hash = await dcaWithBatchOperations()
        } else {
          hash = await dcaWith712Support()
        }

        const rc = await safeWaitForTransactionReceipt(client, {
          address,
          hash,
          confirmations: BLOCK_CONFIRMATIONS
        })

        analytics.track(existingFlowRate > 0 ? 'UPDATE_POSITION' : 'CREATE_POSITION', {
          torex: torex.address,
          user,
          flowRate: JSON.stringify(flowRate)
        })

        success = rc.status === 'success'
      } catch (e) {
        if (!(e instanceof UserRejectedRequestError)) {
          console.error('Failed to initiate DCA.', e)
        }
      } finally {
        setIsLoading(false)
      }

      return { success }
    },
    [
      existingFlowRate,
      client,
      torex,
      userChainId,
      user,
      address,
      isAddressContract,
      writeContractAsync,
      signFlowUpdate,
      getUnderlyingTokenOf,
      isNativeAssetSuperToken
    ]
  )

  return {
    dca,
    isLoading,
    existingFlowRate,
    torex,
    benchmarkQuote: formatEther(benchmarkQuote),
    durationSinceLastLME,
    hasInverse
  }
}
