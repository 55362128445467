'use client'

import { usePrices } from '@/components/providers/PricesProvider'
import { ChainId } from '@/config/networks'
import { getBuiltGraphSdkFor } from '@/subgraph'
import { aggregatePortfolioData, calculateTotalValueStreamed } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useThrottle } from 'react-use'
import { zeroAddress } from 'viem'
import { useAccount, useChainId } from 'wagmi'
import { useAllTorexes } from './useAllTorexes'
import { useBenchmarkQuote } from './useBenchmarkQuote'
import { useTotalAmountReceivedByPoolMember } from './useTotalAmountReceivedByPoolMember'

export const usePortfolio = () => {
  const { address: user } = useAccount()
  const userChainId = useChainId() as ChainId
  const { torexes } = useAllTorexes()

  const { Portfolio } = getBuiltGraphSdkFor(userChainId)

  const benchmarkQuoteByTorex = useBenchmarkQuote()

  const pools = useMemo(() => Object.values(torexes).map(({ distribuitionPool }) => distribuitionPool), [torexes])

  const totalAmountReceivedByPool = useTotalAmountReceivedByPoolMember(user, pools)

  const {
    data,
    isSuccess: isQuerySuccess,
    refetch: refetchPortfolio
  } = useQuery({
    enabled: !!user,
    queryKey: ['portfolio'],
    queryFn: () =>
      Portfolio({
        sender: (user ?? zeroAddress).toLowerCase(),
        poolIds: Object.values(torexes).map(({ distribuitionPool }) => distribuitionPool),
        torexes: Object.keys(torexes).map(addr => addr.toLowerCase())
      })
  })

  useEffect(() => {
    if (userChainId) {
      refetchPortfolio()
    }
  }, [userChainId, refetchPortfolio])

  const prices = usePrices()

  const currentTimeStamp = useThrottle(BigInt(Math.floor(Date.now() / 1000)), 10000)

  const totalValueStreamed = useMemo(
    () => calculateTotalValueStreamed(currentTimeStamp, prices, data),
    [data, prices, currentTimeStamp]
  )

  const totalValueStreamedReference = useMemo(
    () => calculateTotalValueStreamed(currentTimeStamp - 604800n, prices, data),
    [data, prices, currentTimeStamp]
  )

  const portfolio = useMemo(
    () => aggregatePortfolioData(currentTimeStamp, torexes, benchmarkQuoteByTorex, data, totalAmountReceivedByPool),
    [data, torexes, currentTimeStamp, benchmarkQuoteByTorex, totalAmountReceivedByPool]
  )

  const isLoading = useMemo(() => Boolean(user && !isQuerySuccess), [user, isQuerySuccess])

  return {
    portfolio,
    refetchPortfolio,
    totalValueStreamed,
    totalValueStreamedReference,
    isLoading
  }
}
