'use client'

import { useTranslation } from '@/app/i18n/client'
import { ChainId, supportedChainsById } from '@/config/networks'
import { DCAWidgetMode, PROTOCOL_FEE_MULTIPLIER } from '@/constants'
import { useAvgLMEFrequency } from '@/hooks/useAvgLMEFrequency'
import { useBalances } from '@/hooks/useBalances'
import { usePoolEstimationFns } from '@/hooks/usePoolEstimationFns'
import { usePortfolio } from '@/hooks/usePortfolio'
import { useReferrer } from '@/hooks/useReferrer'
import { useRewardToken } from '@/hooks/useRewardToken'
import { useTokenRelations } from '@/hooks/useTokenRelations'
import { useTorex } from '@/hooks/useTorex'
import { createTorexUrl, useTorexQueryParams } from '@/hooks/useTorexQueryParams'
import { useTorexTokens } from '@/hooks/useTorexTokens'
import useUserNetflow from '@/hooks/useUserNetflow'
import { useWrapActions } from '@/hooks/useWrapActions'
import { FlowRate, TokenConfig } from '@/types'
import {
  calculateAmountToWrap,
  calculateStreamEnds,
  calculateTotalBalance,
  cn,
  getFlowRatePerSecond,
  getOriginalFlowRate,
  getStreamedAmountForPeriod,
  mapTimePeriodToSeconds,
  roundDecimals,
  toFixedFromString
} from '@/utils'
import { SuperTokenInfo, TokenInfo } from '@superfluid-finance/tokenlist'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { formatDuration } from 'date-fns'
import { useRouter } from 'next/navigation'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { formatEther, formatUnits } from 'viem'
import { useAccount, useChainId } from 'wagmi'
import { CardSkeleton } from './CardSkeleton'
import { CustomPopover } from './CustomPopover'
import { FlowingBalance } from './FlowingBalance'
import { HSeparator } from './Separator'
import Stepper from './Stepper'
import { Success } from './Success'
import { TokenIcon } from './TokenIcon'
import { TokenInput } from './TokenInput'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion'
import { Button } from './ui/button'
import { Icons } from './ui/icons'
import { Label } from './ui/label'
import { Switch } from './ui/switch'

const defaultFlowRate: FlowRate = {
  amountEth: '',
  period: 'month'
}

interface InboundTokenConfig extends TokenConfig {
  underlying?: TokenInfo
}

type DCAWidgetProps = {
  defaultInboundToken?: TokenConfig
  defaultOutboundToken?: TokenConfig
  mode?: DCAWidgetMode
  showDetails?: boolean
  onClose?: () => void
}

export const DCAWidget: FC<DCAWidgetProps> = ({
  defaultInboundToken,
  defaultOutboundToken,
  mode = DCAWidgetMode.DEFAULT,
  showDetails = false,
  onClose
}) => {
  const { t } = useTranslation(['dca-widget', 'common'])
  const router = useRouter()

  const { address: user, chain, isConnected } = useAccount()
  const userChainId = useChainId() as ChainId
  const isChainSupported = useMemo(() => Boolean(supportedChainsById[chain?.id as ChainId]), [chain])

  const { refetchPortfolio } = usePortfolio()
  const { balances, refetchBalances, isLoading: balancesLoading } = useBalances()
  const { isNativeAssetSuperToken, getUnderlyingTokenOf } = useTokenRelations()
  const { token: rewardToken } = useRewardToken()
  const { estimateUserRewardFlowrate } = usePoolEstimationFns()
  const referrer = useReferrer()

  const { open } = useWeb3Modal()

  const [isSuccess, setSuccess] = useState(false)
  const [skipWrapping, setSkipWrapping] = useState(false)

  const { inToken: queryInToken, outToken: queryOutToken, volume: queryVolume } = useTorexQueryParams()

  const [inboundToken, setInboundToken] = useState<InboundTokenConfig>({
    token: queryInToken ?? defaultInboundToken?.token,
    underlying:
      getUnderlyingTokenOf(queryInToken?.address) ?? getUnderlyingTokenOf(defaultInboundToken?.token?.address),
    flowRate: {
      amountEth: queryVolume ?? defaultInboundToken?.flowRate?.amountEth ?? defaultFlowRate.amountEth,
      period: defaultInboundToken?.flowRate?.period ?? defaultFlowRate.period
    }
  })

  const [outboundToken, setOutBoundToken] = useState<TokenConfig>({
    token: queryOutToken ?? defaultOutboundToken?.token
  })

  const {
    dca,
    isLoading: isDCALoading,
    hasInverse,
    existingFlowRate,
    benchmarkQuote,
    torex
  } = useTorex({
    user,
    inboundToken: inboundToken.token?.address,
    outboundToken: outboundToken.token?.address
  })

  // Reset the tokens if the chainid changes
  const prevChainId = useRef<ChainId>(userChainId)
  // biome-ignore lint/correctness/useExhaustiveDependencies: This is intentional
  useEffect(() => {
    if (userChainId === prevChainId.current) return

    console.log('Chain ID changed', userChainId)

    prevChainId.current = userChainId

    setInboundToken({
      token: defaultInboundToken?.token,
      flowRate: defaultInboundToken?.flowRate,
      underlying: getUnderlyingTokenOf(defaultInboundToken?.token?.address)
    })

    setOutBoundToken({
      token: defaultOutboundToken?.token
    })
  }, [userChainId])

  // Set the tokens if the props are modified
  // This is needed when the widget is used in a modal that is mounted at page load
  useEffect(() => {
    setInboundToken(prev => ({
      token: defaultInboundToken?.token ?? prev.token,
      flowRate:
        existingFlowRate && !prev.flowRate?.amountEth
          ? {
              amountEth: formatEther(roundDecimals(existingFlowRate * mapTimePeriodToSeconds('month'))),
              period: 'month'
            }
          : (prev.flowRate ?? defaultFlowRate),
      underlying: getUnderlyingTokenOf(defaultInboundToken?.token?.address) ?? prev.underlying
    }))

    setOutBoundToken(prev => ({
      token: defaultOutboundToken?.token ?? prev.token
    }))
  }, [defaultInboundToken, defaultOutboundToken, existingFlowRate, getUnderlyingTokenOf])

  const selectToken = useCallback(
    (type: 'in' | 'out') => (token: SuperTokenInfo) => {
      switch (type) {
        case 'in': {
          setInboundToken({
            flowRate: defaultFlowRate,
            token,
            underlying: getUnderlyingTokenOf(token.address)
          })

          if (token.address === outboundToken.token?.address || (inboundToken.token && outboundToken.token)) {
            setOutBoundToken({ flowRate: defaultFlowRate })
          }

          break
        }
        case 'out': {
          setOutBoundToken({
            flowRate: outboundToken.flowRate,
            token
          })

          if (token.address === inboundToken.token?.address || (inboundToken.token && outboundToken.token)) {
            setInboundToken({ flowRate: defaultFlowRate })
          }

          break
        }
      }
    },
    [inboundToken, outboundToken, getUnderlyingTokenOf]
  )

  const switchTokens = useCallback(() => {
    setInboundToken({
      ...outboundToken,
      flowRate: inboundToken.flowRate,
      underlying: getUnderlyingTokenOf(outboundToken.token?.address)
    })
    setOutBoundToken({
      token: inboundToken.token
    })
  }, [inboundToken, outboundToken, getUnderlyingTokenOf])

  const onAmountEthChange = useCallback(
    (amountEth: string) => {
      setInboundToken({
        ...inboundToken,
        flowRate: {
          amountEth,
          period: inboundToken.flowRate?.period ?? 'month'
        },
        underlying: getUnderlyingTokenOf(inboundToken.token?.address)
      })
    },
    [inboundToken, getUnderlyingTokenOf]
  )

  const { inboundBalance, outboundBalance } = useMemo(() => {
    const inboundBalance = balances.find(b => b.token.address === inboundToken.token?.address)
    const outboundBalance = balances.find(b => b.token.address === outboundToken.token?.address)

    return {
      inboundBalance,
      outboundBalance
    }
  }, [balances, inboundToken, outboundToken])

  const tokensSelected = useMemo(
    () => Boolean(inboundToken.token && outboundToken.token),
    [inboundToken, outboundToken]
  )

  const flowRateSelected = useMemo(
    () => inboundToken.flowRate?.amountEth !== '' && Number(inboundToken.flowRate?.amountEth) > 0,
    [inboundToken]
  )

  const streamOverDuration = useMemo(
    () => ({
      day: getStreamedAmountForPeriod(inboundToken.flowRate, 'day'),
      period: getStreamedAmountForPeriod(inboundToken.flowRate)
    }),
    [inboundToken]
  )

  const netFlowRate = useUserNetflow(userChainId, inboundToken.token, user)
  const { approve, wrap, allowance, isLoading: isWrapActionLoading } = useWrapActions(inboundToken.token)

  const amountToWrap = useMemo(() => {
    const outFlowRate = getFlowRatePerSecond(inboundToken.flowRate)

    // If the user has more netflow than the dca flowrate then we don't need to wrap
    if (outFlowRate < netFlowRate) {
      return 0n
    }

    const uncoveredFlowRate = outFlowRate - netFlowRate

    return calculateAmountToWrap({
      flowRate: {
        amountEth: formatEther(uncoveredFlowRate * mapTimePeriodToSeconds('month')),
        period: 'month'
      },
      superTokenBalance: inboundBalance?.token.balance,
      underlyingBalance: inboundBalance?.underlying?.balance,
      underlyingDecimals: inboundBalance?.underlying?.decimals,
      existingFlowRate: existingFlowRate
    })
  }, [inboundToken, inboundBalance, existingFlowRate, netFlowRate])

  const isApprovalNeeded = useMemo(() => {
    if (!inboundToken.token) return false
    if (isNativeAssetSuperToken(inboundToken.token?.address)) return false
    if (skipWrapping) return false

    return (allowance ?? 0n) < amountToWrap
  }, [allowance, skipWrapping, inboundToken, amountToWrap, isNativeAssetSuperToken])

  // TODO: Remove this when the MACRO supports native token upgrades
  const isWrapNeeded = useMemo(() => {
    if (!inboundToken.token) return false
    if (!isNativeAssetSuperToken(inboundToken.token?.address)) return false
    if (skipWrapping) return false

    return 0 < amountToWrap
  }, [skipWrapping, inboundToken, amountToWrap, isNativeAssetSuperToken])

  const cta = useMemo(() => {
    if (!isConnected) {
      return {
        action: () => open(),
        text: t('common:connect.wallet'),
        disabled: false
      }
    }

    if (!isChainSupported) {
      return {
        action: () => open({ view: 'Networks' }),
        text: t('cta.switchNetwork'),
        disabled: false
      }
    }

    if (!tokensSelected) {
      return {
        action: () => {},
        text: t('cta.selectTokens'),
        disabled: true
      }
    }

    if (!flowRateSelected) {
      return {
        action: () => {},
        text: t('cta.setFlowrate'),
        disabled: true
      }
    }

    if (existingFlowRate === getFlowRatePerSecond(inboundToken.flowRate)) {
      return {
        action: () => {},
        text: t('cta.noChanges'),
        disabled: true
      }
    }

    if (isDCALoading || isWrapActionLoading) {
      return {
        action: () => {},
        text: '',
        disabled: true
      }
    }

    if (isApprovalNeeded) {
      return {
        action: async () => {
          const { success } = await approve((amountToWrap * 120n) / 100n)

          if (success) {
            refetchBalances()
          }
        },
        text: t('cta.approveWrap'),
        disabled: false
      }
    }

    if (isWrapNeeded) {
      return {
        action: async () => {
          const { success } = await wrap(amountToWrap)

          if (success) {
            refetchBalances()
          }
        },
        text: t('cta.wrap'),
        disabled: false
      }
    }

    return {
      action: async () => {
        const { success } = await dca({
          flowRate: inboundToken.flowRate,
          referrer,
          amountToWrap
        })

        if (success) {
          setSuccess(true)

          await refetchPortfolio()
          refetchBalances()
        }
      },
      text: existingFlowRate > 0n ? t('cta.updateDCA') : t('cta.startDCA'),
      disabled: false
    }
  }, [
    t,
    inboundToken,
    existingFlowRate,
    refetchPortfolio,
    refetchBalances,
    open,
    referrer,
    approve,
    wrap,
    isWrapNeeded,
    amountToWrap,
    dca,
    isConnected,
    isChainSupported,
    tokensSelected,
    flowRateSelected,
    isDCALoading,
    isWrapActionLoading,
    isApprovalNeeded
  ])

  const error = useMemo(() => {
    if (!isConnected || balancesLoading) {
      return null
    }

    if (Number(inboundToken.flowRate?.amountEth) < 0) {
      return {
        msg: t('error.negativeFlow'),
        type: 'NEGATIVE_FLOW' as const
      }
    }

    const fps = getFlowRatePerSecond(inboundToken.flowRate)

    if (fps > 0n && fps < (torex?.minimumInTokenFlowRate ?? 0n) * -1n) {
      return {
        msg: t('error.lessThanMinimumFlowrate'),
        type: 'LESS_THAN_MIN' as const
      }
    }

    if (calculateTotalBalance(inboundBalance, 'super') < streamOverDuration.day) {
      return {
        msg: t('error.lessThan24h'),
        type: 'LESS_THAN_24H' as const
      }
    }

    if ((inboundBalance?.token.balance ?? 0n) < streamOverDuration.day && skipWrapping) {
      return {
        msg: t('error.lessThan24hWrapped', {
          symbol: inboundBalance?.token?.symbol
        }),
        type: 'LESS_THAN_24H' as const
      }
    }

    return null
  }, [
    inboundToken,
    inboundBalance,
    streamOverDuration,
    t,
    isConnected,
    balancesLoading,
    skipWrapping,
    torex?.minimumInTokenFlowRate
  ])

  const warningMsg = useMemo(() => {
    if (streamOverDuration.period > calculateTotalBalance(inboundBalance, 'super')) {
      return t('warning.lessThanAMonth')
    }

    return null
  }, [streamOverDuration, inboundBalance, t])

  const torexURL = useMemo(
    () =>
      createTorexUrl(
        inboundToken.token,
        outboundToken.token,
        toFixedFromString(formatEther(getStreamedAmountForPeriod(inboundToken.flowRate, 'month')))
      ),
    [inboundToken, outboundToken]
  )

  const [_, copyToClipBoard] = useCopyToClipboard()
  const [copied, setCopied] = useState(false)

  const steps = useMemo(() => {
    const steps = []

    if (isApprovalNeeded) {
      steps.push({ title: 'Approve' })
    } else if (isWrapNeeded) {
      steps.push({ title: 'Wrap' })
    }

    steps.push({ title: existingFlowRate ? 'Update Position' : 'Start DCA' })

    return steps
  }, [existingFlowRate, isApprovalNeeded, isWrapNeeded])

  const activeStep = useMemo(() => {
    if (!tokensSelected || !flowRateSelected || !isConnected || !isChainSupported || isApprovalNeeded || isWrapNeeded) {
      return 0
    }
    return 1
  }, [isConnected, isChainSupported, tokensSelected, flowRateSelected, isApprovalNeeded, isWrapNeeded])

  const avgLMEFrequency = useAvgLMEFrequency(torex)

  const { inboundTokens, outboundTokens } = useTorexTokens({
    selectedInboundToken: inboundToken.token,
    selectedOutboundToken: outboundToken.token
  })

  return isSuccess ? (
    <Success
      text={{
        title: t('dca-widget:success.title'),
        description: t('dca-widget:success.description'),
        ctaText: t('dca-widget:success.cta'),
        accordionTrigger: t('dca-widget:success.details.title')
      }}
      videoOverlay={
        <div className='flex flex-col py-6 z-10 items-center bg-black bg-opacity-60 backdrop-blur-sm rounded-xl border-[0.5px] border-white overflow-y-scroll scroll-ml-10'>
          <p className='font-light text-lg'>{t('dca-widget:success.videoOverlayTitle')}</p>
          <div className='flex justify-center min-w-[200px] gap-1 items-baseline font-semibold text-brand-main'>
            <p className='text-2xl text-brand-main uppercase font-mono'>
              <FlowingBalance
                cutAtSignificantFlowingDecimal
                balance={0n}
                flowRate={getFlowRatePerSecond(inboundToken.flowRate)}
              />
            </p>
            <p className='text-sm'>{inboundToken.token?.symbol}</p>
          </div>
        </div>
      }
      accordionContent={
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between' data-testid='success-flow-rate'>
            <p className='text-gray-400 font-light'>{t('dca-widget:success.details.rows.1')}</p>
            <p>{`${inboundToken.flowRate?.amountEth} ${inboundToken.token?.symbol} / ${inboundToken.flowRate?.period}`}</p>
          </div>
          <div className='flex justify-between' data-testid='success-market'>
            <p className='text-gray-400 font-light'>{t('dca-widget:success.details.rows.2')}</p>
            <div className='flex gap-1'>
              <div className='flex gap-1'>
                {inboundToken.token && <TokenIcon token={inboundToken.token} />}
                <p data-testid='success-market-inbound-token'>{inboundToken.token?.symbol}</p>
              </div>
              /
              <div className='flex gap-1'>
                {outboundToken.token && <TokenIcon token={outboundToken.token} />}
                <p data-testid='success-market-outbound-token'>{outboundToken.token?.symbol}</p>
              </div>
            </div>
          </div>

          <div className='flex justify-between' data-testid='success-fee'>
            <p className='text-gray-400 font-light'>{t('dca-widget:success.details.rows.3')}</p>
            <p>{`${
              Number(inboundToken.flowRate?.amountEth) * PROTOCOL_FEE_MULTIPLIER
            } ${inboundToken.token?.symbol} / ${inboundToken.flowRate?.period}`}</p>
          </div>
          <div className='flex justify-between' data-testid='success-avg-payout-time'>
            <p className='text-gray-400 font-light'>{t('dca-widget:success.details.rows.4')}</p>
            <p>
              {avgLMEFrequency > 0
                ? `~${formatDuration({
                    minutes: avgLMEFrequency
                  })}`
                : '--'}
            </p>
          </div>
        </div>
      }
      onBack={() => onClose?.() ?? router.push('/portfolio', { scroll: false })}
    />
  ) : (
    <CardSkeleton
      title={
        mode === DCAWidgetMode.DEFAULT && (
          <div className='w-full flex justify-between items-center'>
            {t('title')}
            {torexURL && (
              <CustomPopover
                triggerAsChild
                trigger={
                  <Button
                    data-testid='invite-link-copy-clipboard-button'
                    size='sm'
                    variant='ghost'
                    onClick={() => {
                      if (!copied) {
                        copyToClipBoard(torexURL)
                        setCopied(true)
                        setTimeout(() => {
                          setCopied(false)
                        }, 3000)
                      }
                    }}
                    className={cn('h-6 p-1', !copied && 'hover:bg-gray-500')}
                  >
                    {copied ? (
                      <Icons.check size={16} className='animate-fade text-brand-main w-full' />
                    ) : (
                      <Icons.copy size={16} className='animate-fade w-full' />
                    )}
                  </Button>
                }
                content={<p className='text-gray-400 text-xs tracking-widest'>{t('copyConfig')}</p>}
              />
            )}
          </div>
        )
      }
      description={mode === DCAWidgetMode.DEFAULT && t('description')}
      error={error?.msg}
      warning={warningMsg}
      cta={
        <Button
          data-testid='swap-button'
          disabled={cta.disabled || error !== null}
          loading={isDCALoading || isWrapActionLoading}
          variant='brand'
          size='lg'
          className='rounded-lg w-full'
          onClick={cta.action}
        >
          {cta.text}
        </Button>
      }
    >
      <div data-testid='dca-widget' className='flex flex-col gap-1 relative'>
        <TokenInput
          dataTestId='in-token-selection'
          token={inboundToken.token}
          flowRate={inboundToken.flowRate}
          balance={calculateTotalBalance(inboundBalance, 'super')}
          quickSetMode='matchInflow'
          selectedNetwork={supportedChainsById[userChainId]}
          tokens={inboundTokens}
          title={t('inputs.intoken')}
          netFlow={netFlowRate}
          onTokenSelect={selectToken('in')}
          onAmountChange={onAmountEthChange}
          className='h-28'
          displayBalanceMode='mixed'
          displaySelectedTokenMode='underlying'
        />
        <button
          type='button'
          disabled={!hasInverse}
          title={hasInverse ? 'Switch tokens' : 'No inverse TOREX found'}
          className={cn(
            'absolute border-2 border-gray-600 bg-black left-[calc(50%-20px)] top-24 p-2 rounded-xl hover:bg-brand-dark',
            !hasInverse && '  cursor-not-allowed'
          )}
          onClick={switchTokens}
        >
          {hasInverse ? <Icons.swapBidirectional className='-rotate-90' /> : <Icons.swapUnidirectinal />}
        </button>
        <TokenInput
          dataTestId='out-token-selection'
          token={outboundToken.token}
          flowRate={inboundToken.flowRate}
          balance={outboundBalance?.token.balance ?? 0n}
          quickSetMode='none'
          amount={'1'}
          disabled
          tokens={outboundTokens}
          title={t('inputs.outtoken')}
          selectedNetwork={supportedChainsById[userChainId]}
          onTokenSelect={selectToken('out')}
          onAmountChange={onAmountEthChange}
          displaySelectedTokenMode='underlying'
        />

        <div className='bg-black px-4 rounded-lg gap-2 mt-1.5'>
          <Accordion
            data-testid='details-accordion'
            type='single'
            collapsible
            defaultValue={showDetails ? 'item-1' : undefined}
          >
            <AccordionItem className='border-none' value='item-1'>
              <AccordionTrigger>
                <p className='text-gray-200 text-xs font-semibold uppercase tracking-widest'>
                  {tokensSelected
                    ? t('details.exchangeRate', {
                        symbol1: inboundToken.token?.symbol,
                        benchmarkQuote: toFixedFromString(benchmarkQuote),
                        symbol2: outboundToken.token?.symbol
                      })
                    : t('details.title')}
                </p>
              </AccordionTrigger>
              <AccordionContent className='flex flex-col gap-1'>
                <DetailsRow
                  title={t('details.minimumFlowrate.title')}
                  tooltipContent={t('details.minimumFlowrate.tooltip', {
                    inbound: inboundToken.token?.symbol ?? 'Inbound Tokens',
                    outbound: outboundToken.token?.symbol ?? 'Outbound Tokens',
                    reward: rewardToken.symbol
                  })}
                  value={
                    torex
                      ? `${toFixedFromString(
                          formatEther(
                            getStreamedAmountForPeriod(getOriginalFlowRate(torex.minimumInTokenFlowRate * -1n, 'month'))
                          )
                        )} ${torex.inboundToken.symbol}`
                      : '--'
                  }
                  error={error?.type === 'LESS_THAN_MIN'}
                />

                <HSeparator className='my-4' />

                <DetailsRow
                  title={t('details.inflowRate.title', {
                    symbol: inboundToken?.token?.symbol
                  })}
                  tooltipContent={t('details.inflowRate.tooltip', {
                    symbol: inboundToken?.token?.symbol ?? 'Super Token'
                  })}
                  value={toFixedFromString(formatEther(netFlowRate * mapTimePeriodToSeconds('month')))}
                />

                <DetailsRow
                  title={t('details.availableBalance.title', {
                    symbol: inboundToken?.underlying?.symbol ?? 'Underlying Token'
                  })}
                  tooltipContent={t('details.availableBalance.tooltip', {
                    symbol: inboundToken?.underlying?.symbol ?? 'Underlying Token'
                  })}
                  value={toFixedFromString(
                    formatUnits(inboundBalance?.underlying?.balance ?? 0n, inboundBalance?.underlying?.decimals ?? 18)
                  )}
                />
                <DetailsRow
                  title={t('details.availableSuperBalance.title', {
                    symbol: inboundToken.token?.symbol ?? 'Super Token'
                  })}
                  tooltipContent={t('details.availableSuperBalance.tooltip', {
                    symbol: inboundToken.token?.symbol ?? 'Super Token'
                  })}
                  value={toFixedFromString(
                    formatUnits(inboundBalance?.token?.balance ?? 0n, inboundBalance?.token?.decimals ?? 18)
                  )}
                />
                <DetailsRow
                  title={t('details.wrapAmount.title')}
                  tooltipContent={t('details.wrapAmount.tooltip', {
                    symbol: inboundBalance?.underlying?.symbol ?? 'Underlying Token'
                  })}
                  value={
                    amountToWrap > 0n && !skipWrapping
                      ? toFixedFromString(formatUnits(amountToWrap, inboundBalance?.underlying?.decimals ?? 18))
                      : '--'
                  }
                />

                <HSeparator className='my-4' />

                <DetailsRow
                  title={t('details.fee.title')}
                  tooltipContent={t('details.fee.tooltip')}
                  value={
                    inboundToken.flowRate?.amountEth
                      ? t('details.fee.value', {
                          amount: toFixedFromString(
                            (Number(inboundToken.flowRate?.amountEth) * PROTOCOL_FEE_MULTIPLIER).toString()
                          ),
                          symbol: inboundToken.token?.symbol,
                          period: inboundToken.flowRate?.period
                        })
                      : t('details.fee.default', {
                          percentage: 0.5,
                          period: inboundToken.flowRate?.period
                        })
                  }
                />

                <DetailsRow
                  title={t('details.avgPayout.title')}
                  tooltipContent={t('details.avgPayout.tooltip')}
                  value={
                    avgLMEFrequency > 0
                      ? `~${formatDuration({
                          minutes: avgLMEFrequency
                        })}`
                      : '--'
                  }
                />

                <DetailsRow
                  title={t('details.reward.title')}
                  tooltipContent={t('details.reward.tooltip', {
                    symbol: rewardToken.symbol
                  })}
                  value={
                    tokensSelected && flowRateSelected
                      ? t('details.reward.value', {
                          number: toFixedFromString(estimateUserRewardFlowrate(torex, inboundToken.flowRate).amountEth),
                          symbol: rewardToken.symbol,
                          period: inboundToken.flowRate?.period
                        })
                      : '--'
                  }
                />

                <DetailsRow
                  title={t('details.streamEnds.title')}
                  tooltipContent={t('details.streamEnds.tooltip')}
                  value={
                    tokensSelected && flowRateSelected
                      ? calculateStreamEnds(
                          (inboundBalance?.token.balance ?? 0n) + (skipWrapping ? 0n : amountToWrap),
                          (netFlowRate ?? 0n) - getFlowRatePerSecond(inboundToken.flowRate) + existingFlowRate
                        )
                      : '--'
                  }
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>

        {amountToWrap > 0n && (
          <div className='flex items-center space-x-2 mt-2'>
            <Switch
              data-testid='skip-wrapping-button'
              checked={skipWrapping}
              onCheckedChange={checked => setSkipWrapping(checked)}
              id='skip-wrap'
              className='scale-75 data-[state=unchecked]:bg-gray-400'
              thumbClassName='data-[state=checked]:bg-brand-main data-[state=unchecked]:bg-gray-500'
            />
            <CustomPopover
              trigger={
                <div className='flex items-center gap-1 rounded-md cursor-pointer'>
                  <Label htmlFor='skip-wrap'>{t('wrap.skip')}</Label>
                  <Icons.info className='fill-gray-400' size={12} />
                </div>
              }
              content={<p className='text-gray-400 text-xs tracking-widest'>{t('wrap.tooltip')}</p>}
            />
          </div>
        )}

        {steps.length > 1 && <Stepper activeStep={activeStep} steps={steps} className='pt-4' />}
      </div>
    </CardSkeleton>
  )
}

type DetailsRowProps = {
  title: string
  value: string
  tooltipContent: string
  error?: boolean
  className?: string
}

const DetailsRow = ({ title, error, value, tooltipContent }: DetailsRowProps) => {
  const testIdTitle = title.replace(/\s+/g, '').toLowerCase()
  return (
    <div data-testid={`details-${testIdTitle}`} className='flex justify-between'>
      <CustomPopover
        trigger={
          <div
            className={cn(
              'flex items-center gap-1 rounded-md cursor-pointer',
              error ? 'text-red-error' : 'text-gray-400'
            )}
          >
            <p className='text-xs tracking-widest'>{title}</p>
            <Icons.info className={error ? 'fill-red-error' : 'fill-gray-400'} size={12} />
          </div>
        }
        content={
          <p
            data-testid={`details-tooltip-${testIdTitle}`}
            className={cn('text-xs', error ? 'text-red-error' : 'text-gray-400')}
          >
            {tooltipContent}
          </p>
        }
      />

      <p className='text-xs text-gray-400'>{value}</p>
    </div>
  )
}
